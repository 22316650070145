//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

//Theme font

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;300;400;500;600;700;900&family=Sarabun:wght@100;300;400;500;600;700;800&display=swap');

// $font-family-base:            						'Poppins', 'Noto Sans Thai', sans-serif !default;
$font-family-base: 'Sarabun', sans-serif !default;
// Theme colors
// Override primary color variants
$primary: #28527A; // Bootstrap variable
$primary-hover: #3E83A8; // Custom variable
$primary-light: #ffe1e1; // Custom variable
$primary-inverse: #FFFFFF; // Custom variable

// Secondary colors
$secondary: #3E83A8 !default; // Bootstrap variable
$secondary-hover: darken($secondary, 4%) !default; // Custom variable
$secondary-light: #937d46 !default; // Custom variable
$secondary-inverse: #F2EBDA !default; // Custom variable

// Success colors
$success: #6B8A47 !default; // Bootstrap variable
$success-hover: #309f89 !default; // Custom variable
$success-light: #C9F7F5 !default; // Custom variable
$success-inverse: #FFFFFF !default; // Custom variable

// Warning colors
$warning: #FEA73F !default; // Bootstrap variable
$warning-hover: #e3ae59 !default; // Custom variable
$warning-light: #FFF4DE !default; // Custom variable
$warning-inverse: #FFFFFF !default; // Custom variable

// Danger colors
$danger: #E00000 !default; // Bootstrap variable

$info: #3CC4FF !default; // Bootstrap variable
$info-hover: #052347 !default; // Custom variable
$info-light: #EEE5FF !default; // Custom variable
$info-inverse: #FFFFFF !default; // Custom variable

$dark: #000000 !default; // Bootstrap variable
$dark-hover: darken($dark, 3%) !default; // Custom variable
$dark-light: #D1D3E0 !default; // Custom variable
$dark-inverse: #FFFFFF !default; // Custom variable

// White colors
$white: #ffff !default; // Bootstrap variable
$white-hover: #F3F6F9 !default; // Custom variable
$white-light: #FFFFFF !default; // Custom variable
$white-inverse: #3F4254 !default; // Custom variable


// $font-size-lg:               	 	$font-size-base * 1.08 !default;   
$font-size-sm: 16px !default;
$font-size-xs: 18px !default;
$font-size-xxs: 16px !default;

$h1-font-size: 40px !default;
$h2-font-size: 25px !default;
$h3-font-size: 30px !default;
$h4-font-size: 25px !default;
$h5-font-size: 20px !default;
$h6-font-size: 20px !default;

$modal-content-bg: $white-light !default;

.animation-underline {
  position: relative;
  color: #000;
  text-decoration: none;
}

.animation-underline:hover {
  color: #000;
}

.bg-hover {
  transition: background-color 250ms ease-out;
}

.bg-hover:hover {
  background-color: #FEA73F !important;
}

.required:after {
  content: " *";
  color: red;
}

.required {
  font-size: 1rem !important;
}

.animation-underline::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $primary;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.animation-underline:hover::before {
  transform: scaleX(1);
}

body {
  background-color: white;
}

$F4: #FFFFFF !default;
$C3: #F2F3EE !default;
$F2: #979797 !default;
$F1: #000000 !default;

th {
  background-color: $primary;
  color: $F4;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: $F4 !important;
}

.text-c3 {
  color: $C3;
}

.text-f2 {
  color: $F2;
}

p {
  font-size: 18px;
  margin: 0;
}

.bg-c3 {
  background-color: $C3;
}

.text-f1 {
  color: $F1;
}

.text-f2 {
  color: $F2;
}

.text-f4 {
  color: $F4;
}

.table thead th {
  font-size: 16px;
}

.checkbox-xl::before,
.checkbox-xl::after {
  width: 1.85rem;
  height: 1.85rem;
}

.checkbox>span {
  background-color: #F0F0F0;
}

.checkbox-xl .custom-control-label {
  // padding-top: 23px;
  padding-left: 10px;
}


.custom-control .custom-control-input {
  background-color: red;
}

ul {
  // display: block;
  // list-style-type: disc;
  // margin-block-start: 1em;
  // margin-block-end: 1em;
  // margin-inline-start: 0px;
  // margin-inline-end: 0px;
  // padding-inline-start: 40px;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 8px;
}

ol li {
  list-style: decimal;
}

li {
  display: list-item;
}

ul li {
  list-style-type: disc;
}

.select:after {
  content: 'ᨆ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 12px;
  background-color: $primary;
  color: #fff;
  font-weight: bold;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dateCustom:after {
  font-family: FontAwesome;
  content: "\f073";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 12px;
  background-color: $primary;
  color: #fff;
  font-weight: bold;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dateCustom {
  position: relative;
  width: 100%;
}

input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent !important;
}

.form-check-input:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -1px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.form-check-input:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -1px;
  left: -1px;
  position: relative;
  background-color: $primary;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input.myDate {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: $white;
  border: 1px solid $F2;
  width: 100%;
  height: 100%;
  min-height: 40px;
  padding: 6px 15px;
  font-size: 1rem;
  border-radius: 4px;
}

*:focus {
  outline: none;
}

.select {
  position: relative;
  width: 100%;
}

.shadow-custom {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
}

select.mySelectArrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: $white;
  border: 1px solid $F2;
  width: 100%;
  height: 40px;
  padding: 6px 15px;
  font-size: 1rem;
  border-radius: 4px;
}

.border-f2 {
  border-color: $F2 !important;
}

select.mySelectArrow option {
  color: #000;
  padding: 0 10px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.fv-plugins-message-container .fv-help-block {
  color: #F44336;
  font-size: $font-size-xs;
  font-weight: 400;
}

.placeholder-red::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1;
  /* Firefox */
}

.fw-bold {
  font-weight: bold;
}

.fw-medium {
  font-weight: 500;
}

.placeholder-red:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

.placeholder-red::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.font-large {
  font-size: $h5-font-size !important;
}

.is-invalid {
  border-color: #8C1515;
}

.form-control {
  background-color: #F0F0F0;
  border-color: #F6F6F6;
}

.text-grey {
  color: #6E6E6E;
}

.border-grey {
  border-color: #E6E6E6 !important;
}

.border-light-grey {
  border-color: #F2EBDA !important;
}

.bg-grey {
  background-color: #E6E6E6 !important;
}

.bg-dark-grey {
  background-color: #6E6E6E !important;
}

.bg-light-grey {
  background-color: #F6F6F6 !important;
}

.bg-light-grey-white {
  background-color: #F0F0F0 !important;
}

.text-333 {
  color: #333333;
}

.header-paddingTop {
  padding-top: 70px;
}

.h-60px {
  height: 60px;
}

.menu-mobile {
  display: none;
}

.menu-justify {
  justify-content: end;
}

.leaning-flex {
  flex-direction: row;
}

.eleaning-display {
  flex-direction: row;
}

.eleaning-display-sub {
  flex-direction: row;
}

.eleaning-sub {
  width: 32%;
}

.leaning-mr {
  margin-right: 16.25px;
}

.dateCustom .react-datepicker-wrapper {
  width: 100%;
}

select {
  border-right: 16px solid #F0F0F0 !important;
  background-color: #F0F0F0;
}

.my-selected {
  background-color: $primary;
  color: $F4;
}

@media only screen and (max-width: 1000px) {
  .header-paddingTop {
    padding-top: 5px;
  }

  .menu-mobile {
    display: initial;
  }

  .menu-justify {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1555px) {
  .leaning-flex {
    flex-direction: column;
  }

  .leaning-mr {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1400px) {
  .eleaning-display {
    flex-direction: column;
    align-items: center;
  }

}

@media only screen and (max-width: 1230px) {
  .eleaning-display-sub {
    flex-direction: column;
  }

  .eleaning-sub {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16.25px;
  }
}


.input-block {
  position: relative;
}

.radio.radio-accent.radio-white>span::after {
  background-color: #FFFFFF;
  border-color: #E6E6E6;
  border-style: solid;
  border-width: 2px;
}

.radio.radio-accent.radio-success>span::after {
  background-color: #44AB96;
  border-color: #44AB96;
  border-style: solid;
  border-width: 2px;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #E6E6E6;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #979797 !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #979797 !important;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #979797 !important;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #979797 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #979797 !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #979797 !important;
}

::selection {
  background: transparent;
}

::-moz-selection {
  background: transparent;
}
.main-container {
  position: relative
}